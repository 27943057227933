export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'primary'
      }
    },
    {
      title: true,
      name: 'Indicadores',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
        {
          name: 'Grandes Numeros',
          url: '/indicadores/grandes-numeros',
          icon: 'icon-chart'
        },
        {
          name: 'Top 5',
          url: '/indicadores/top',
          icon: 'icon-chart'
        },
        {
          name: 'Seguridad',
          url: '/indicadores/seguridad',
          icon: 'icon-chart'
        },
        {
          name: 'Apoyo',
          url: '/indicadores/apoyo',
          icon: 'icon-chart'
        },
        {
          name: 'Inocuidad Alimentaria',
          url: '/indicadores/inocuidad-alimentaria',
          icon: 'icon-chart'
        },
        {
          name: 'Nuestra Gente',
          url: '/indicadores/nuestra-gente',
          icon: 'icon-chart'
        },
        {
          name: 'Higiene y Seguridad',
          url: '/indicadores/higiene-seguridad',
          icon: 'icon-chart'
        },
        {
          name: 'Gestión de Residuos',
          url: '/indicadores/residuos',
          icon: 'icon-chart'
        },
        {
          name: 'Otros',
          url: '/indicadores/otros',
          icon: 'icon-chart'
        }
  ]
}
