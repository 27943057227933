<template>
      <TheFooter>
        <div>
          <!--footer-->
          <a href="https://www.bloqs4.com">Blosq4</a>
          <span class="ml-1">&copy; 2019</span>
        </div>
        <div class="ml-auto">
          <span class="mr-1">Powered by</span>
          <a href="https://coreui.io">Bloqs4</a>
        </div>
      </TheFooter>
</template>
<script>
    import {Footer as TheFooter} from '@coreui/vue'

    export default {
  name: 'DefaultFooter',
  components: {
    TheFooter
  },
}
</script>
